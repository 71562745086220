.main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding: 20px 0;
    height: 100%;
}

.header {
    width: auto;
}

.appBarStyle {
    position: relative;
    top: 0px;
    height: 100px;
    background: #f0f4fa;
    padding-top: 20px;
    padding-left: 50px;
}

.appBarStyleWelsh {
    position: absolute;
    top: 0px;
    height: 100px;
    background: #f0f4fa;
    border-top: 20px solid #0063aa;
    padding-top: 10px;
    padding-left: 45%;
}

.candidateHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    padding-right: 20px;
}

.candidateTabContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0px;
    margin-top: 10px;
    width: 100%;
}

.candidateInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
    padding-top: 10px;
}

.candidateContact {
    padding: 20px;
    padding-bottom: 10px;
    padding-left: 5px;
}

.candidateTopBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.candidateRanking {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 0px !important;
}



.header p {
    text-align: center;
}

.cloudform {
    position: relative;
    width: 100%;
}

.chatForm {
    width: 100%;
}

.textarea {
    position: relative;
    resize: none;
    font-size: 1.1rem;
    padding: 1rem 2rem 1rem 2rem;
    /*width: 60vw;*/
    border-radius: 0.5rem;
    border: 1px solid #d9d9e3;
    background: #ffffff;
    color: #000;
    outline: none;
}

.textarea:disabled {
    opacity: 0.5;
}

.textarea:focus {
    outline: none;
    border-color: #6b7280;
    box-shadow: 0 0 0 3px rgba(156, 163, 175, 0.5);
}

.textarea::placeholder {
    color: #6b7280;
}

.generatebutton {
    position: absolute;
    top: 0.87rem;
    right: 1rem;
    color: rgb(165, 162, 162);
    background: none;
    padding: 0.3rem;
    border: none;
    display: flex;
}

.loadingwheel {
    position: absolute;
    top: 0.2rem;
    right: 0.25rem;
}

.svgicon {
    transform: rotate(90deg);
    width: 1.2em;
    height: 1.2em;
    fill: currentColor;
}

.generatebutton:hover {
    background: #e4575726;
    border-radius: 0.2rem;
}

.generatebutton:disabled {
    opacity: 0.9;
    cursor: not-allowed;
    background: none;
}

.messagelist {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    border-radius: 0.5rem;
}

.messagelistloading {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 1rem;
}

.usermessage {
    background: #ffffff;
    padding: 1rem;
    color: #000;
}

.usermessagewaiting {
    padding: 1.5rem;
    color: #000;
    background: linear-gradient(to left, #07080938, #1a1c2024, #07080936);
    background-size: 200% 200%;
    background-position: -100% 0;
    animation: loading-gradient 2s ease-in-out infinite;
    animation-direction: alternate;
    animation-name: loading-gradient;
}

@keyframes loading-gradient {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

.apimessage {
    background: #f9fafb;
    padding: 1rem;
    color: #000;
    animation: fadein 0.5s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.apimessage,
.usermessage,
.usermessagewaiting {
    display: flex;
}

.markdownanswer {
    line-height: 1.75;
}

.messageCustom {
    margin-top: 9px;
    margin-left: 4px;
    margin-bottom: 0px;
}

.messageCustom p {
    margin: 2px;
}

.markdownanswer a:hover {
    opacity: 0.8;
}

.markdownanswer a {
    color: #b13a3a;
    font-weight: 500;
}

.markdownanswer code {
    color: #15cb19;
    font-weight: 500;
    white-space: pre-wrap !important;
}

.markdownanswer ol,
.markdownanswer ul {
    margin: 1rem;
}

.boticon,
.usericon {
    margin-right: 1rem;
    border-radius: 0.1rem;
    height: 100%;
}

.markdownanswer h1,
.markdownanswer h2,
.markdownanswer h3 {
    font-size: inherit;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 1rem;
    flex-direction: column;
}

.cloud {
    /*width: 60vw;*/
    /*height: 65vh;*/
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 0.5rem;
    border: 1px solid #d9d9e3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pointsnormal {
    width: 90%;
    height: 90%;
}

.pointsdim {
    width: 90%;
    height: 90%;
    opacity: 0.25;
}

.footer {
    color: #5f6368;
    font-size: 0.8rem;
    margin: 1.5rem;
}

.footer a {
    font-weight: 500;
    color: #7a7d81;
}

.footer a:hover {
    opacity: 0.8;
}

/* Mobile optimization */
@media (max-width: 600px) {
    .main {
        padding: 1rem;
        max-height: 90vh;
    }

    .cloud {
        width: 22rem;
        height: 28rem;
    }
    .textarea {
        width: 22rem;
    }
    .topnav {
        border: 1px solid black;
        align-items: center;
        padding: 0.85rem 0.75rem 0.85rem 0.75rem;
    }

    .navlogo {
        font-size: 1.25rem;
        width: 20rem;
    }

    .markdownanswer code {
        white-space: pre-wrap !important;
    }

    .footer {
        font-size: 0.7rem;
        width: 100%;
        text-align: center;
    }
}

